const CONSTANTS = {
  HEADER: {
    BUTTON_PUBLISHING: 'Publicar Na loja',
    BUTTON_CANCEL_ALL: 'Cancelar todas as alterações',
  },
  ADDED_POSTS: 'Posts Adcionados:',
  DELETED_POSTS: 'Posts deletados:',
  EDITED_POSTS: 'Posts Editados:',
  EXPAND: 'Expandir posts',
  MINIMIZE: 'Minimizar posts',
  DATE_PUBLISHING: 'Data da publicação',
  SUCCESS_PUBLISHING: 'Publicação realizado com sucesso!',
  EMPTY_PUBLISHING: 'Alexa sem conteúdo no momento! \n Adicione novas publicações clicando em editar logo acima.',
  FEED_CHARACTER_LIMIT: 4500,
};

export default CONSTANTS;
