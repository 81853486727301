import React from 'react';

import styled from 'styled-components';

import CONSTANTS_PUBLISHING from '../constants';

type Props = {
  charactersLenght: number;
  className?: string;
};

const CharactersLimit: React.FC<Props> = ({ charactersLenght, className = '' }) => {
  return (
    <Container className={className} closeLimit={charactersLenght > CONSTANTS_PUBLISHING.FEED_CHARACTER_LIMIT - 300}>
      <span>
        {charactersLenght} / {CONSTANTS_PUBLISHING.FEED_CHARACTER_LIMIT}
      </span>
      <h3>{'Total Feed  /  Limite Alexa'}</h3>
    </Container>
  );
};

export default CharactersLimit;

const Container = styled.p<{ closeLimit: boolean }>`
  display: flex;
  flex-direction: column;

  span {
    ${({ closeLimit }) => (closeLimit ? 'color: red !important' : '')};
  }

  h3 {
    white-space: break-spaces;
    color: rgb(0 0 0 / 80%);
  }
`;
