import { Reducer } from 'redux';

import { Types, State, Action } from './types';

const INITIAL_STATE: State = {
  data: null,
  loading: false,
  error: false,
};

const PublishingReducer: Reducer<State, Action> = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Types.LOAD_UPDATE:
    case Types.LOAD_SUCCESS:
      return loadSuccess(state, action);
    case Types.LOAD_REQUEST:
      return loadRequest(state);
    case Types.LOAD_FAILURE:
      return loadFailure(state);
    case Types.CLEAR_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

/*
 * Handlers
 */
const loadSuccess = (state: State, action: Action): State => ({
  data:
    action.payload === null
      ? action.payload
      : {
          ...action.payload,
          addedPost: [],
          deletedPost: [],
          editedPost: [],
          posts: action.payload ? action.payload.posts.map((p) => ({ ...p, edited: false })) : [],
        },
  loading: false,
  error: false,
});
const loadRequest = (state: State): State => ({
  ...state,
  loading: true,
  error: false,
});
const loadFailure = (state: State): State => ({
  ...state,
  loading: false,
  error: true,
});

export default PublishingReducer;
