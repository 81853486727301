import { AnyAction } from 'redux';

export enum Types {
  LOAD_REQUEST = '@publishing/LOAD_RESQUEST',
  LOAD_UPDATE = '@publishing/LOAD_UPDATE',
  LOAD_SUCCESS = '@publishing/LOAD_SUCCESS',
  LOAD_FAILURE = '@publishing/LOAD_FAILURE',
  CLEAR_STATE = '@publishing/CLEAR_STATE',
}

export type Post = {
  content: string;
  id: string;
  edited?: boolean;
  title: string;
};

export type Publishing = null | {
  date: Date | null;
  redirectionUrl: string;
  deletedPost: Array<string>;
  editedPost: Array<string>;
  addedPost: Array<string>;
  posts: Array<Post>;
};

export type State = {
  readonly data: Publishing;
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = Publishing;

export interface Action extends AnyAction {
  payload: Payload;
}
