import 'date-fns';
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

import CONSTANTS from '../constants';

type Props = {
  disabled: boolean;
  date: Date | null | undefined;
  handleDateChange: (date: Date | null) => void;
};

const DatePublishing: React.FC<Props> = (props) => {
  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={props.disabled}
          margin="normal"
          id="date-picker-dialog"
          label={CONSTANTS.DATE_PUBLISHING}
          format="dd/MM/yyyy"
          value={props.date}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default DatePublishing;

const Container = styled.section`
  margin: 4vh 2% 2vh 2%;

  .MuiFormLabel-root {
    font-weight: bold;
  }

  .MuiInput-formControl {
    margin-top: 28px;
  }
`;
