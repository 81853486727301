import styled from 'styled-components';

export const DRAWER_WIDTH = 240;

export const Container = styled.article<{ isMenuOpen: boolean }>`
  margin: 10%;
  margin-top: 100px;

  ${({ isMenuOpen }) => (isMenuOpen ? `margin-left: ${DRAWER_WIDTH + 30}px;` : '')}
`;
