import { put } from 'redux-saga/effects';

import { ApiService } from '../../../services';
import { UserDTO } from '../../../services/api';
import { genereteId } from '../../../Utils';
import ErrorUtils from '../../../Utils/ErrorUtils';

import { loadSuccess, loadFailure } from './actions';

export function* loadUsers() {
  try {
    const response = yield ApiService.getUsers();
    const data: Array<UserDTO> = response?.data;

    if (!response || response.status !== 200) {
      throw ErrorUtils.CustomException(`Error get users API response - ${JSON.stringify(response)}`, response?.status?.toString());
    }

    if (!data) {
      throw new Error('Invalid response from get users API');
    }

    yield put(loadSuccess(data.map((e) => ({ ...e, id: genereteId(e.email.length.toString()) }))));
  } catch (err) {
    yield put(loadFailure());
    console.error(err);
  }
}
