export const setTimeoutOnClick = (func?: () => void, time?: number) => func && setTimeout(func, time || 300);

export const genereteId = (sufix: string): string =>
  (
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substr(2, 5)
  ).toUpperCase() + sufix;

export const debounce = (_function: () => void, timeOut: number) => {
  const callback = setTimeout(_function, timeOut);
  return () => clearTimeout(callback);
};
