import React, { ReactElement } from 'react';

import { Grow } from '@material-ui/core';

type Props = {
  in: boolean;
  timeout: number;
  children: ReactElement<any, any>;
};

const TransitionEffect: React.FC<Props> = (props) => {
  return (
    <Grow in={props.in} timeout={props.timeout}>
      {props.children}
    </Grow>
  );
};

export default TransitionEffect;
