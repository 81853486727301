import { Post, Publishing } from '../../../../store/ducks/publishing/types';

/**
 * Metodo para adicionar ou remover elementos da lista de edições.
 *
 * @param newPost: Novo post que foi editado ou criado;
 * @param newPublishing: Nova Publicação em edição na memoria;
 * @param currentPublish: Atual Publicação na loja da Amazon;
 */
export const addNewPostInPublishing = (newPost: Post, newPublishing: Publishing, currentPublishing: Publishing): Publishing => {
  if (newPublishing === null) return null;

  const posts = newPublishing.posts;
  const oldPost = posts.find((e) => e.id === newPost.id);

  if (oldPost) {
    let index;
    posts.find((p, i) => (p.id === oldPost.id ? (index = i) : false));

    if (index !== undefined) {
      const newPosts = [...newPublishing.posts];
      newPosts[index] = newPost;
      const isNewPost = currentPublishing?.posts.find((p) => p.id === newPost.id) === undefined;
      const editedPost =
        newPublishing.editedPost.includes(newPost.id) || isNewPost ? newPublishing.editedPost : [...newPublishing.editedPost, newPost.id];
      return { ...newPublishing, posts: newPosts, editedPost };
    }
  }

  return { ...newPublishing, posts: [newPost].concat(posts), addedPost: [...newPublishing.addedPost, newPost.id] };
};

/**
 * Metodo para deletar post da publicação em edição.
 *
 * @param id: Id de post par deletar;
 * @param newPublishing: Nova Publicação em edição na memoria;
 * @param currentPublish: Atual Publicação na loja da Amazon;
 */
export const deletePostInPublishing = (id: string, newPublishing: Publishing, currentPublishing: Publishing): Publishing => {
  if (newPublishing === null) return null;
  const newPosts = newPublishing.posts.filter((p) => p.id !== id);
  const editedPost = newPublishing.editedPost.filter((e) => e !== id);
  const addedPost = newPublishing.addedPost.filter((e) => e !== id);
  const isNewPost = currentPublishing?.posts.find((p) => p.id === id) === undefined;
  const deletedPost = isNewPost ? newPublishing.deletedPost : [...newPublishing.deletedPost, id];
  return { ...newPublishing, posts: newPosts, deletedPost, editedPost, addedPost };
};

/**
 * Metodo para resetar edições de um post especifico por id
 *
 * @param id: Id de post par deletar;
 * @param newPublishing: Nova Publicação em edição na memoria;
 * @param currentPublish: Atual Publicação na loja da Amazon;
 *
 */
export const resetEditPostInPublishing = (post: Post, newPublishing: Publishing, currentPublish: Publishing): Publishing => {
  if (newPublishing === null || currentPublish === null) return null;

  const oldPost = currentPublish.posts.find((p) => p.id === post.id);

  if (!oldPost) return newPublishing;

  let index;
  newPublishing.posts.find((p, i) => (p.id === oldPost.id ? (index = i) : false));

  if (index === undefined) return newPublishing;

  const newPosts = [...newPublishing.posts];
  oldPost.edited = false;
  newPosts[index] = oldPost;
  const editedPost = newPublishing.editedPost.filter((e) => e !== post.id);

  return { ...newPublishing, posts: newPosts, editedPost };
};

/**
 * Metodo para mover posts na lista de edição
 *
 * @param id: Id de post par deletar;
 * @param newPublishing: Nova Publicação em edição na memoria;
 * @param currentPublish: Atual Publicação na loja da Amazon;
 *
 */
export const movePostInPublishing = (action: 'up' | 'down', id: string, newPublishing: Publishing): Publishing => {
  if (newPublishing === null) return null;

  let index;
  newPublishing.posts.find((p, i) => (p.id === id ? (index = i) : false));

  if (index === undefined) return newPublishing;

  const post = newPublishing.posts[index];
  const newPosts = [...newPublishing.posts];
  newPosts.splice(index, 1);

  action === 'up' ? newPosts.splice(index - 1, 0, post) : newPosts.splice(index + 1, 0, post);

  return { ...newPublishing, posts: newPosts };
};

/**
 * Metodo para adicionar data publicação em edição
 *
 * @param date: data da publicacao;
 * @param newPublishing: Nova Publicação em edição na memoria;
 *
 */
export const addDateInPublishing = (date: Date | null, newPublishing: Publishing): Publishing => {
  if (newPublishing === null) return null;
  return { ...newPublishing, date: date };
};
