import React from 'react';

import { TextField } from '@material-ui/core';
import styled from 'styled-components';

type Props = {
  className?: string;
  disabled: boolean;
  maxLength?: number;
  label?: string;
  text: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
};

const InputText: React.FC<Props> = ({placeholder = '', label, className = '', disabled, text, onChange, maxLength }) => {
  return (
    <StyleText className={className} inEditing={!disabled}>
      <TextField
        disabled={disabled}
        multiline
        value={text}
        placeholder={placeholder}
        onChange={onChange}
        name={label}
        variant="outlined"
        inputProps={{ maxLength }}
      />
    </StyleText>
  );
};

export default InputText;

const StyleText = styled.span<{ inEditing: boolean }>`
  .MuiInputBase-root.Mui-disabled {
    ${({ inEditing }) => (inEditing ? '' : 'color: initial !important')};
  }
  fieldset {
    ${({ inEditing }) => (inEditing ? '' : 'border: none !important')};
  }
`;
