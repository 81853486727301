import { action } from 'typesafe-actions';

import { User } from '../users/types';

import { Types, Payload } from './types';

export const logout = () => action(Types.LOGOUT);
export const updateUserLogged = (user: User) => action(Types.UPDATE_USER_LOGGED, { user });
export const loadRequest = (user: string, password: string) => action(Types.LOAD_REQUEST, { user, password });
export const loadSuccess = (data: Payload) => action(Types.LOAD_SUCCESS, data);
export const loadFailure = () => action(Types.LOAD_FAILURE);
export const clearState = () => action(Types.CLEAR_STATE);
