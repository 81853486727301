import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import styled from 'styled-components';

type Props = {
  onClick: () => void;
};

const AddButton: React.FC<Props> = (props: Props) => {
  return (
    <ButtonNewPostContainer>
      <IconButton color="primary" onClick={props.onClick}>
        <Tooltip title="Adicionar">
          <AddCircleOutline />
        </Tooltip>
      </IconButton>
    </ButtonNewPostContainer>
  );
};

export default AddButton;

const ButtonNewPostContainer = styled.section`
  text-align: center;
  margin-bottom: 2vh;

  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
`;
