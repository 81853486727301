import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';

import CONSTANTS from '../../constants';
import { ApplicationState } from '../../store';
import { loadRequest } from '../../store/ducks/login/actions';
import { clearState as clearStateLogin, updateUserLogged } from '../../store/ducks/login/actions';
import { clearState as clearStatePublishing } from '../../store/ducks/publishing/actions';
import { clearState as clearStateUsers } from '../../store/ducks/users/actions';
import { User } from '../../store/ducks/users/types';

import LoginView from './LoginView';

type StateProps = {
  authenticated: boolean;
  error: boolean;
  loading: boolean;
  wrongPassword: boolean;
  user: User;
};

type DispatchProps = {
  updateUserLogged: (user: User) => void;
  loadRequest: (user: string, password: string) => void;
  clearStatePublishing: () => void;
  clearStateLogin: () => void;
  clearStateUsers: () => void;
};

const Login: React.FC<StateProps & DispatchProps> = (props) => {
  if (props.authenticated) {
    props.clearStatePublishing();
    props.clearStateUsers();
    props.clearStateLogin();
    props.clearStateLogin();
    props.updateUserLogged(props.user);
    return <Redirect to={{ pathname: CONSTANTS.ROUTES.HOME }} />;
  }

  return <LoginView loading={props.loading} error={props.error} wrongPassword={props.wrongPassword} sendLogin={props.loadRequest} />;
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.auth.user,
  authenticated: state.auth.authenticated,
  error: state.auth.error,
  loading: state.auth.loading,
  wrongPassword: state.auth.wrongPassword,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ loadRequest, clearStatePublishing, clearStateLogin, clearStateUsers, updateUserLogged }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
