import React from 'react';

import { IconButton, makeStyles, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import ToolbarView from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import styled from 'styled-components';

import { DRAWER_WIDTH } from '../styles';

type Props = {
  children?: never;
  handleMenu: () => void;
  isOpen: boolean;
  label: string;
  user: string;
};

const Toolbar: React.FC<Props> = (props: Props) => {
  const style = useStyles();

  const appBarClassName = clsx(style.appBar, {
    [style.appBarShift]: props.isOpen,
  });

  const iconButtonClassName = clsx(style.menuButton, props.isOpen && style.hide);

  return (
    <AppBar position="fixed" className={appBarClassName}>
      <ToolbarView>
        <IconButton color="inherit" aria-label="open drawer" onClick={props.handleMenu} edge="start" className={iconButtonClassName}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {props.label}
        </Typography>
        <User>
          <Typography variant="h6" noWrap>
            {props.user}
          </Typography>
        </User>
      </ToolbarView>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
}));

const User = styled.span`
  margin-left: auto;
`;

export default Toolbar;
