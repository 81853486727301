import React, { useState } from 'react';
import { connect } from 'react-redux';

import Logout from '../../components/Logout';
import { ApplicationState } from '../../store';

import Menu from './components/Menu';
import Publishing from './components/publishing';
import Toolbar from './components/Toolbar';
import Users from './components/users';
import CONSTANTS from './constants';
import { Container } from './styles';

type StateProps = {
  userEmail: string;
};

const Home: React.FC<StateProps> = ({ userEmail }) => {
  const [state, setState] = useState({
    open: false,
    optionSelected: CONSTANTS.MENU.NEWS,
  });

  const handleMenu = () => setState((prev) => ({ ...prev, open: !prev.open }));

  const handleOptionMenu = (id: number) => {
    const optionSelected = Object.values(CONSTANTS.MENU).find((o) => o.id === id);
    setState((prev) => ({
      ...prev,
      optionSelected: optionSelected || CONSTANTS.MENU.NEWS,
    }));
  };

  return (
    <main>
      <Toolbar handleMenu={handleMenu} isOpen={state.open} label={state.optionSelected.label} user={userEmail} />
      <Menu handleMenu={handleMenu} handleOptionMenu={handleOptionMenu} isOpen={state.open} label={state.optionSelected.label} />
      <Container isMenuOpen={state.open}>
        {state.optionSelected.id === CONSTANTS.MENU.NEWS.id && <Publishing />}
        {state.optionSelected.id === CONSTANTS.MENU.USERS.id && <Users />}
        {state.optionSelected.id === CONSTANTS.MENU.EXIT.id && <Logout />}
      </Container>
    </main>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userEmail: state.auth.user.email,
});

export default connect(mapStateToProps)(Home);
