import { AnyAction } from 'redux';

export enum Types {
  LOAD_REQUEST = '@users/LOAD_RESQUEST',
  LOAD_UPDATE = '@users/LOAD_UPDATE',
  LOAD_SUCCESS = '@users/LOAD_SUCCESS',
  LOAD_FAILURE = '@users/LOAD_FAILURE',
  CLEAR_STATE = '@users/CLEAR_STATE',
}

export type User = {
  id: string;
  name: string;
  email: string;
  admin: boolean;
  password?: string;
};

export type State = {
  readonly data: Array<User>;
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = Array<User>;

export interface Action extends AnyAction {
  payload: Payload;
}
