import { combineReducers } from 'redux';

import loginReducer from './login/authReducer';
import publishingReducer from './publishing/publishingReducer';
import usersReducer from './users/usersReducer';

export const rootReducer = combineReducers({
  auth: loginReducer,
  publishing: publishingReducer,
  users: usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
