import { put } from 'redux-saga/effects';

import { ApiService, AuthService } from '../../../services';
import { AuthDTO } from '../../../services/api';
import ErrorUtils from '../../../Utils/ErrorUtils';

import { loadSuccess, loadFailure } from './actions';
import { initialUser } from './authReducer';
import { PayloadLoadRequest } from './types';

export function* loadAuth(action: PayloadLoadRequest) {
  const user = initialUser();

  try {
    user.email = action.payload.user;

    const response = yield ApiService.authentication(action.payload.user, action.payload.password);
    const data: AuthDTO = response?.data;

    if (!response || response.status !== 200) {
      throw ErrorUtils.CustomException(`Error auth API response - ${JSON.stringify(response)}`, response?.status?.toString());
    }

    if (!data || !data.token || !data.expires_at) {
      throw new Error('Invalid response from authentication API');
    }

    AuthService.saveToken({ ...data, user: action.payload.user });

    yield put(loadSuccess({ authenticated: true, wrongPassword: false, user }));
  } catch (err) {
    const error = ErrorUtils.getMetadataRequestError(err);

    if (error.code?.toString() === '401' || error.code?.toString() === '400') {
      yield put(loadSuccess({ authenticated: false, wrongPassword: true, user }));
    } else {
      yield put(loadFailure());
    }

    console.error(err);
  }
}
