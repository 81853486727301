const CONSTANTS = {
  STORAGE: {
    AUTH: 'thenews_control',
  },

  ROUTES: {
    HOME: '/',
    LOGIN: '/login',
  },

  MESSAGES: {
    ERROR_REQUEST: 'Ocorreu um erro ao tentar realizar a solicitação.',
    TRY_AGAIN: 'Tentar novamente?',
  },
};

export default CONSTANTS;
