import React from 'react';

import { Paper as PaperBackground } from '@material-ui/core';

type Props = {
  children: React.ReactNode;
  component: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  className?: string;
  style?: React.CSSProperties;
};

const Paper: React.FC<Props> = ({ className = '', style = {}, children, component }) => {
  return (
    <PaperBackground className={className} elevation={0} component={component} style={{ padding: '2%', ...style }}>
      {children}
    </PaperBackground>
  );
};

export default Paper;
