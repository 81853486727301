import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CONSTANTS from '../constants';
import Home from '../pages/home';
import Login from '../pages/login';

import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path={CONSTANTS.ROUTES.HOME} component={Home} />
      <Route exact path={CONSTANTS.ROUTES.LOGIN} component={Login} />
      <Redirect from="*" to={CONSTANTS.ROUTES.HOME} />
    </Switch>
  );
};

export default Routes;
