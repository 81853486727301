import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import CONSTANTS from '../constants';
import { AuthService } from '../services';

interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        AuthService.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: CONSTANTS.ROUTES.LOGIN, state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
