import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Footer } from './components';
import Routes from './routes';
import store from './store';
import ThemeGlobalProvider from './styles/provider';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeGlobalProvider>
        <BrowserRouter>
          <Routes />
          {false && <Footer />}
        </BrowserRouter>
      </ThemeGlobalProvider>
    </Provider>
  );
};

export default App;
