export default {
  MESSAGES: {
    RESET_PASSWORD: {
      ERROR_REQUEST_API: 'Ocorreu um erro ao tentar fazer a solicitação. Entre em contato com o administrador.',
      LABEL_REQUEST_PASSWORD: 'Esqueci minha senha',
      SUCCESS: `Se o e-mail informado acima estiver cadastrado no sistema, será enviado uma nova senha de acesso. 
      \n Após receber a nova senha, tente acessar novamente e lembre-se de alterar sua senha no painel de usuarios.`,
      TEXT_INTRO: 'Digite seu e-mail abaixo para alterar sua senha',
    },
    LOGIN: {
      ERROR_REQUEST_API: 'Ocorreu um erro ao tentar fazer a solicitação. Entre em contato com o administrador.',
      ERRO_REQUEST_LOGIN: 'Ocorreu um erro ao tentar efetuar login, por favor tente novamente mais tarde!',
      USER_OR_PASS_WRONG: 'Usuário ou senha incorreta',
    },
  },

  BUTTONS: {
    LOGIN: {
      LABEL_ENTER: 'Acessar',
      LABEL_USER: 'E-mail',
      LABEL_PASSWORD: 'Senha',
    },
    RESET_PASSWORD: {
      LABEL_BUTTON: 'Enviar',
    },
  },
};
