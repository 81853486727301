export default {
  MENU: {
    NEWS: {
      id: 0,
      label: 'Publicações',
    },
    USERS: {
      id: 1,
      label: 'Usuários',
    },

    EXIT: {
      id: 2,
      label: 'Sair',
    },
  },
};
