import React from 'react';

import { Button, ButtonGroup, FormControlLabel, FormHelperText, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { Loading } from '..';

import Alert from '../Alert';
import Paper from '../Paper';

import Error from './Error';

type OwnProps = {
  inEditing?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  hasUpdate: boolean;
  infoMessage?: string;
  errorMessage?: string;
  showInfo?: boolean;
  typeInfo?: 'error' | 'warning' | 'success';
  labelSaveButton: string;
  labelCancelButton: string;

  errorOnClickTryAgain?: () => void;
  onClickCancelAll: () => void;
  toggleEdit: () => void;
  onClickPublish: () => void;
};

type Props = OwnProps;

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Container hasUpdate={props.hasUpdate}>
      <Paper component="section" className={`Header-buttons Buttons ${classes.root}`}>
        <ButtonGroup disableElevation variant="contained" color="primary" size="large">
          <Button
            id="Button-publish"
            size="large"
            className={`Disable-${!props.hasUpdate}`}
            disabled={!props.hasUpdate}
            onClick={props.onClickPublish}
          >
            {props.labelSaveButton}
          </Button>
          <Button
            id="Button-cancel-all"
            size="large"
            className={`Disable-${!props.hasUpdate}`}
            disabled={!props.hasUpdate}
            onClick={props.onClickCancelAll}
          >
            {props.labelCancelButton}
          </Button>
        </ButtonGroup>

        <FormControlLabel
          id="Buttons-edit"
          control={
            <span style={{ display: 'flex' }}>
              <FormHelperText>off</FormHelperText>
              <Switch checked={props.inEditing} onChange={props.toggleEdit} color="primary" />
              <FormHelperText>on</FormHelperText>
            </span>
          }
          label="Modo Editor"
          labelPlacement="top"
        />
      </Paper>

      {(props.isLoading || props.isError || props.showInfo) && (
        <Paper component="section" style={{ display: 'flex', justifyContent: 'center' }}>
          <Info className={`${props.isLoading ? '' : 'Messages-info'}`}>
            {props.isLoading && <Loading />}
            {props.isError && <Error message={props.errorMessage} onClickTryAgain={props.errorOnClickTryAgain} />}
            {props.showInfo && <Alert message={props.infoMessage || ''} type={props.typeInfo || 'error'} />}
          </Info>
        </Paper>
      )}
    </Container>
  );
};

export default Header;

const Container = styled.header<{ hasUpdate: boolean }>`
  .Header-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  #Buttons-edit {
    margin-left: auto;
  }

  #Button-publish,
  #Button-cancel-all {
    color: ${({ hasUpdate }) => (hasUpdate ? 'white' : '')};
    font-weight: ${({ hasUpdate }) => (hasUpdate ? 'bold' : 'normal')};
  }

  #Button-publish {
    background-color: #3c9863;
  }

  #Button-cancel-all {
    background-color: #e02727;
  }

  .Disable-true {
    background-color: inherit !important;
  }

  .Messages-info {
    width: fit-content;
    height: fit-content;
  }
`;

const Info = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh 0vw;
  height: 140px;

  #Button-try-again {
    margin: 20px 0;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));
