const CONSTANTS = {
  HEADER: {
    BUTTON_SAVE: 'Salvar Alterações',
    BUTTON_CANCEL_ALL: 'Cancelar todas as alterações',
  },
  SUCCESS_PUBLISHING: 'Publicação realizado com sucesso!',
  ERRO_PUBLISHING: `
  Verifique se:
  \n (1) E-mails devem estar no formato correto "exemplo@endereco.com"; 
  \n (2) Campos "E-mail" e "Nome" não podem estar em branco, campos são obrigatórios';
  \n (3) É Necessário pelo menos 1 dos usuários ser administrador;
  \n (4) Não é possível salvar e-mails duplicados;
  `,

  HEADER_LIST: {
    ADMINISTRATOR: 'Administrador',
    NAME: 'Nome',
    EMAIL: 'E-mail',
    NEW_PASSWORD: 'Nova Senha',
    DELETE: 'Excluir Usuário',
  },
};

export default CONSTANTS;
