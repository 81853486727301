import React from 'react';

import styled from 'styled-components';

import LogoBackground from '../../assets/images/logo_background.png';
import { Loading } from '../../components';
import Alert from '../../components/Alert';
import Paper from '../../components/Paper';

import ChangePassword from './components/ChangePassword';
import FormLogin from './components/FormLogin';
import CONSTANTS from './constants';

type Props = {
  sendLogin: (email: string, password: string) => void;
  error: boolean;
  loading: boolean;
  wrongPassword: boolean;
};

const LoginView: React.FC<Props> = (props) => {
  return (
    <Container>
      <Title>
        <h1>Alexa Controll</h1>
        <h4>the news | letter</h4>
      </Title>

      <Paper component="section" style={{ textAlign: 'center' }}>
        <Login>
          <FormLogin disableInputs={props.loading} sendLogin={props.sendLogin} />
          <ChangePassword />

          {props.loading && <Loading />}
          {props.error && <Alert message={CONSTANTS.MESSAGES.LOGIN.ERRO_REQUEST_LOGIN} type="error" />}
          {props.wrongPassword && <Alert message={CONSTANTS.MESSAGES.LOGIN.USER_OR_PASS_WRONG} type="warning" />}
        </Login>
      </Paper>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  .Form-components {
    margin: 10px 5%;
    width: 100%;
    max-width: 420px;
  }
`;

const Title = styled.header`
  width: 100%;
  text-align: center;

  padding: 130px;
  padding-bottom: 60px;
  background-position: 53vw 110% !important;
  background-size: 120px !important;
  background-repeat: no-repeat !important;
  background-image: url(${LogoBackground}) !important;

  background-color: ${({ theme }) => theme.colors.background.primary};
`;

const Login = styled.span`
  & > h1 {
    margin: 5%;
  }
`;

export default LoginView;
