import React from 'react';

import { Button } from '@material-ui/core';

import CONSTANTS from '../../constants';
import Alert from '../Alert';

type Props = {
  onClickTryAgain?: () => void;
  message?: string;
  className?: string;
};

const Error: React.FC<Props> = ({ message = '', onClickTryAgain, className = '' }) => {
  return (
    <React.Fragment>
      <Alert className={className} message={`${CONSTANTS.MESSAGES.ERROR_REQUEST} ${'\n\n' + message}`} type="error" />
      {onClickTryAgain && (
        <Button id="Button-try-again" variant="contained" color="primary" onClick={onClickTryAgain}>
          {CONSTANTS.MESSAGES.TRY_AGAIN}
        </Button>
      )}
    </React.Fragment>
  );
};

export default Error;
