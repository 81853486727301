import axios, { AxiosRequestConfig } from 'axios';

import environments from '../environments';

import AuthService from './auth';

const API = axios.create({
  baseURL: environments.baseURL,
});

export type PostDTO = {
  content: string;
  title: string;
};

export type PublishingDTO = null | {
  date: string;
  redirectionUrl: string;
  posts: Array<PostDTO>;
};

export type UserDTO = {
  email: string;
  name: string;
  admin: boolean;
  password?: string;
};

export type AuthDTO = {
  token: string;
  expires_at: number;
};

const buildConfigRequest = (data?: AxiosRequestConfig): AxiosRequestConfig => {
  return {
    ...data,
    headers: {
      Authorization: `Bearer ${AuthService.getToken()}`,
    },
  };
};

const delay = (start: number, callback: () => void) => {
  const SECONDS_DELAY = 1500;
  const applyDelay = Date.now() - start < SECONDS_DELAY;

  if (applyDelay) setTimeout(() => callback(), SECONDS_DELAY);
  else callback();
};

const post = async <T>(path: string, data?: T, config?: AxiosRequestConfig): Promise<{ status: number; data: T }> => {
  const start = Date.now();
  return new Promise((resolve, reject) => {
    API.post(path, data, config)
      .then((res) => delay(start, () => resolve({ status: res.status, data: res.data })))
      .catch((e) => delay(start, () => reject(e)));
  });
};

const get = async <T>(path: string, config: AxiosRequestConfig): Promise<{ status: number; data: T }> => {
  const start = Date.now();
  return new Promise((resolve, reject) => {
    API.get(path, config)
      .then((res) => delay(start, () => resolve({ status: res.status, data: res.data })))
      .catch((e) => delay(start, () => reject(e)));
  });
};

export default {
  authentication: (user: string, password: string) => post<AuthDTO>('/login', undefined, { headers: { user, password } }),
  deleteUsers: (users: Array<UserDTO>) => post<Array<UserDTO>>('/users', users, buildConfigRequest()),
  getCurrentPublishing: () => get<PublishingDTO>('/messages/current', buildConfigRequest()),
  getUsers: () => get<Array<UserDTO>>('/users', buildConfigRequest()),
  resetPassword: (user: string) => post<void>(`/users/${user}/new-password`),
  saveUsers: (users: Array<UserDTO>) => post<Array<UserDTO>>('/users', users, buildConfigRequest()),
  sendPublishing: (publishing: PublishingDTO) => post<PublishingDTO>('/messages', publishing, buildConfigRequest()),
};
