import React from 'react';

import { AccordionSummary, Button, IconButton, Tooltip } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {
  ExpandMore as ExpandMoreIcon,
  DeleteForeverOutlined as DeleteIcon,
  Check as CheckIcon,
  Clear as CancelIcon,
  RotateLeft as RotateLeftIcon,
  ArrowDropUp as MoveUpIcon,
  ArrowDropDown as MoveDownIcon,
} from '@material-ui/icons';
import styled from 'styled-components';

import InputText from '../../../../../../components/InputText';
import TransitionEffect from '../../../../../../components/Transition';
import StringUtils from '../../../../../../Utils/StringUtils';
import CharactersLimit from '../CharactersLimit';

import CONSTANTS from './constants';

type Props = {
  content: string;
  edited?: boolean;
  expand: boolean;
  charactersLenght: number;
  charactersPostLenght: number;
  index: number;
  inEditing: boolean;
  isFirst: boolean;
  isLast: boolean;
  salved: boolean;
  title: string;

  handleExpand: () => void;
  onClickCancel: () => void;
  onClickResetEdit: () => void;
  onClickDelete: () => void;
  onClickSave: () => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onClickMove: (action: 'up' | 'down') => void;
};

const PostView: React.FC<Props> = (props) => {
  return (
    <TransitionEffect in={true} timeout={1000}>
      <Container edited={props.edited}>
        {props.inEditing && (
          <MoveButtonsContainer>
            <Button className="Buttons" variant="contained" size="small" onClick={() => props.onClickMove('up')} disabled={props.isFirst}>
              <MoveUpIcon name="up" />
            </Button>
            <Button className="Buttons" variant="contained" size="small" onClick={() => props.onClickMove('down')} disabled={props.isLast}>
              <MoveDownIcon name="down" />
            </Button>
          </MoveButtonsContainer>
        )}

        <Accordion expanded={props.expand}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            IconButtonProps={{ onClick: props.handleExpand }}
            aria-label="Expand"
            aria-controls={`additional-actions${props.index}-content`}
            id={`additional-actions${props.index}-header`}
          >
            <InputText
              disabled={!props.inEditing}
              onChange={props.onChange}
              text={props.title}
              placeholder={StringUtils.isNullOrEmpty(props.title) ? 'Titulo do Post' : ''}
              label="title"
              className="Inputs Input-title"
              maxLength={CONSTANTS.TITLE_LIMIT}
            />
          </AccordionSummary>

          <AccordionDetails>
            <PostContent>
              <InputText
                disabled={!props.inEditing}
                onChange={props.onChange}
                text={props.content}
                placeholder={StringUtils.isNullOrEmpty(props.content) ? 'Conteúdo do Post' : ''}
                className="Inputs"
                label="content"
              />

              {props.inEditing && (
                <EditSection>
                  <h5>
                    <span className="Post-caracter">characters:  </span>
                    {props.charactersPostLenght}
                  </h5>
                  <CharactersLimit className="Characters-limit" charactersLenght={props.charactersLenght} />

                  <div>
                    <IconButton color="primary" onClick={props.onClickDelete}>
                      <Tooltip title={CONSTANTS.BUTTONS_LABEL.DELETE}>
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton color="primary" onClick={props.onClickResetEdit} disabled={!props.edited}>
                      <Tooltip title={CONSTANTS.BUTTONS_LABEL.DISCART}>
                        <RotateLeftIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton color="primary" onClick={props.onClickCancel} disabled={props.salved}>
                      <Tooltip title={CONSTANTS.BUTTONS_LABEL.CANCEL}>
                        <CancelIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton color="primary" onClick={props.onClickSave} disabled={props.salved}>
                      <Tooltip title={CONSTANTS.BUTTONS_LABEL.SAVE}>
                        <CheckIcon />
                      </Tooltip>
                    </IconButton>
                  </div>
                </EditSection>
              )}
            </PostContent>
          </AccordionDetails>
        </Accordion>
      </Container>
    </TransitionEffect>
  );
};

export default PostView;

const Container = styled.section<{ edited?: boolean }>`
  display: flex;

  .MuiAccordion-root {
    width: 100%;
    background-color: ${({ edited, theme }) => (edited ? theme.colors.select : '')} !important;
  }

  .Inputs {
    width: 100%;

    .MuiTextField-root {
      width: 100%;
    }
  }

  .Input-title {
    textarea {
      font-weight: bold;
    }
  }

  .MuiAccordionSummary-root .Mui-focused {
    background-color: ${({ theme }) => theme.colors.background.paper} !important;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    .Mui-focused {
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }

  .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 30%), 0px 1px 1px 0px rgb(0 0 0 / 24%), 0px 1px 3px 0px rgb(0 0 0 / 35%);
  }
`;

const MoveButtonsContainer = styled.section`
  display: flex;
  flex-direction: column;
  place-content: space-evenly;

  .Buttons {
    color: rgba(0, 0, 0, 0.15);
    &,
    :hover {
      background-color: initial;
      border: none;
      box-shadow: none;
    }
    :hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
  }
  .Mui-disabled {
    color: white !important;
    background-color: initial !important;
  }
`;

const PostContent = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EditSection = styled.section`
  width: 100%;
  text-align: end;
  margin: 10px 0;

  .Characters-limit {
    h3 {
      font-size: 0.6em !important;
    }
  }

  .Post-caracter {
    color: rgb(0 0 0 / 35%);
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;
