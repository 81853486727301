import React, { Fragment } from 'react';

import { Divider } from '@material-ui/core';
import styled from 'styled-components';

import AddButton from '../../../../components/buttons/AddButton';
import Header from '../../../../components/HeaderEdition';
import Paper from '../../../../components/Paper';
import TransitionEffect from '../../../../components/Transition';
import { User } from '../../../../store/ducks/users/types';

import CONSTANTS from './constants';
import UserItem from './UserItem';

type Props = {
  error: boolean;
  hasUpdate: boolean;
  inEditing: boolean;
  loading: boolean;
  userLoggedIsAdmin: boolean;
  users: Array<User>;

  savingUsers: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };

  addNewUser: () => void;
  deleteUser: (id: string) => void;
  loadCurrentUsers: () => void;
  onClickCancelAll: () => void;
  toggleEdit: () => void;
  onClickPublish: () => void;
  saveUser: (user: User) => void;
};

const UsersView: React.FC<Props> = (props) => {
  const isLoading = props.loading || props.savingUsers.loading;
  const isError = props.error || props.savingUsers.error;
  const showInfo = props.savingUsers.success;
  const infoMessage = CONSTANTS.SUCCESS_PUBLISHING;
  const errorMessage = CONSTANTS.ERRO_PUBLISHING;

  return (
    <Container userLoggedIsAdmin={props.userLoggedIsAdmin} inEditing={props.inEditing}>
      <Header
        hasUpdate={props.hasUpdate}
        inEditing={props.inEditing}
        isError={isError}
        isLoading={isLoading}
        labelCancelButton={CONSTANTS.HEADER.BUTTON_CANCEL_ALL}
        labelSaveButton={CONSTANTS.HEADER.BUTTON_SAVE}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        showInfo={showInfo}
        typeInfo="success"
        errorOnClickTryAgain={props.error ? props.loadCurrentUsers : undefined}
        onClickCancelAll={props.onClickCancelAll}
        toggleEdit={props.toggleEdit}
        onClickPublish={props.onClickPublish}
      />

      <Divider style={{ margin: '2vh 0' }} />

      <TransitionEffect in={true} timeout={1000}>
        <section>
          {props.users.length !== 0 && (
            <Fragment>
              {props.userLoggedIsAdmin && props.inEditing && (
                <Paper component="section" style={{ width: '100%' }}>
                  <AddButton onClick={props.addNewUser} />
                </Paper>
              )}

              <Paper component="header">
                {props.userLoggedIsAdmin && <h5>{CONSTANTS.HEADER_LIST.ADMINISTRATOR}</h5>}
                <h5>{CONSTANTS.HEADER_LIST.NAME}</h5>
                <h5>{CONSTANTS.HEADER_LIST.EMAIL}</h5>
                <h5>{CONSTANTS.HEADER_LIST.NEW_PASSWORD}</h5>
                {props.userLoggedIsAdmin && <h5>{CONSTANTS.HEADER_LIST.DELETE}</h5>}
              </Paper>

              <Paper component="ul">
                {props.users.map((user, i) => (
                  <UserItem
                    admin={user.admin}
                    adminInputsDisabled={!props.userLoggedIsAdmin}
                    className="List-items"
                    email={user.email}
                    id={user.id}
                    index={i}
                    inEditing={props.inEditing}
                    key={user.id + i}
                    name={user.name}
                    onClickDelete={props.deleteUser}
                    onChange={props.saveUser}
                  />
                ))}
              </Paper>
            </Fragment>
          )}
        </section>
      </TransitionEffect>
    </Container>
  );
};

export default UsersView;

const Container = styled.section<{ userLoggedIsAdmin: boolean; inEditing: boolean }>`
  & > section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > section > header,
  & > section > ul {
    padding: 2%;
    width: 100%;
    max-width: ${({ userLoggedIsAdmin }) => (userLoggedIsAdmin ? 'auto' : '70%')};
  }

  & > section > header,
  .List-items {
    display: grid;
    grid-template-columns: ${({ userLoggedIsAdmin }) => (userLoggedIsAdmin ? '1fr 1fr 2fr 1fr 1fr' : 'repeat(3, 1fr)')};
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    text-align: center;
  }

  .List-items {
    margin-bottom: 20px;
    justify-items: center;

    .E-mail,
    .E-mail .MuiFormControl-root {
      width: 100%;
    }

    textarea {
      text-align: center;
    }

    & > button {
      width: fit-content;
      height: fit-content;
    }

    .Mui-checked {
      color: ${({ inEditing, theme }) => (inEditing ? theme.colors.primary.main : '')};
    }
  }
`;
