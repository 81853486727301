import React from 'react';

import { Button } from '@material-ui/core';
import DialogView from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

type Props = {
  children: any;
  onClickClose(): void;
  open: boolean;
  title?: string;
};

const Dialog: React.FC<Props> = (props) => {
  return (
    <DialogView
      className="Dialog-show"
      open={props.open}
      onClose={props.onClickClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>

      <DialogContent dividers={true}>{props.children}</DialogContent>

      <DialogActions>
        <Button onClick={props.onClickClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </DialogView>
  );
};

export default Dialog;
