import CONSTANTS from '../constants';
import { Token } from '../routes/types';

import StorageService from './storage';

const getTokenInLocalStorage = (): Token | null => {
  try {
    return StorageService.get<Token>(CONSTANTS.STORAGE.AUTH);
  } catch (err) {
    StorageService.delete(CONSTANTS.STORAGE.AUTH);
    console.error(err);
    return null;
  }
};

const isAuthenticated = (): boolean => {
  const token = getTokenInLocalStorage();
  return !!token && !!token.expires_at && new Date(token.expires_at).getTime() > Date.now();
};

const getToken = (): string | undefined => getTokenInLocalStorage()?.token;

const getUser = (): string => {
  const token = getTokenInLocalStorage();
  return token && token.user ? token.user : '';
};

const logout = (): void => {
  StorageService.delete(CONSTANTS.STORAGE.AUTH);
};

const saveToken = (token: Token): void => {
  StorageService.save<Token>(CONSTANTS.STORAGE.AUTH, token);
};



const AuthService = {
  getToken,
  getUser,
  isAuthenticated,
  logout,
  saveToken,
};

export default AuthService;
