import React, { Fragment } from 'react';

import { Divider, Link } from '@material-ui/core';
import styled from 'styled-components';

import Alert from '../../../../components/Alert';
import AddButton from '../../../../components/buttons/AddButton';
import Header from '../../../../components/HeaderEdition';
import Paper from '../../../../components/Paper';
import { Post as PostType, Publishing } from '../../../../store/ducks/publishing/types';

import CharactersLimit from './components/CharactersLimit';
import DatePublishing from './components/DatePublishing';
import Post from './components/Post';
import CONSTANTS from './constants';

type Props = {
  feedCharactersLength: number;
  error: boolean;
  expandPosts: boolean;
  hasUpdate: boolean;
  inEditing: boolean;
  loading: boolean;
  publishing: Publishing;
  publishingToStore: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };

  addNewPost: () => void;
  savePost: (newPost: PostType) => void;
  deletePost: (id: string) => void;
  resetEditPost: (post: PostType) => void;
  movePost: (action: 'up' | 'down', id: string) => void;

  handleDateChange: (date: Date | null) => void;
  handleExpandPosts: () => void;
  toggleEdit: () => void;
  onClickPublish: () => void;
  onClickCancelAll: () => void;
  loadCurrentPublishing: () => void;
};

const PublishingsView: React.FC<Props> = (props) => {
  const UpdatesInfo = ({ hasUpdate, added, edited, deleted }: { hasUpdate: boolean; added: number; edited: number; deleted: number }) => (
    <LabelUpdatesCount hasUpdate={hasUpdate}>
      <span>{hasUpdate && `${CONSTANTS.ADDED_POSTS} ( ${added} )`}</span>
      <span>{hasUpdate && `${CONSTANTS.EDITED_POSTS} ( ${edited} )`}</span>
      <span>{hasUpdate && `${CONSTANTS.DELETED_POSTS} ( ${deleted} )`}</span>
    </LabelUpdatesCount>
  );

  const ExpandAll = () => (
    <Link
      component="button"
      variant="body2"
      onClick={props.handleExpandPosts}
      style={{ width: '100%', textAlign: 'end', marginBottom: '20px' }}
    >
      <b> {props.expandPosts ? CONSTANTS.MINIMIZE : CONSTANTS.EXPAND}</b>
    </Link>
  );

  /**
   * DESABILITADO EDIÇÃO DE DATA POR RECURSO ATUAL DO ALEXIA LER APENAS OS DADOS DO DIA,
   * LOGO, NÃO É POSSIVEL AINDA PROGRAMAR DATAS DE PUBLICAÇÃO
   *
   */
  const TEMPORARY_DISABLE = false;

  const isLoading = props.loading || props.publishingToStore.loading;
  const isError = props.error || props.publishingToStore.error;
  const showInfo = props.publishingToStore.success;
  const messageInfo = CONSTANTS.SUCCESS_PUBLISHING;
  const isEmptyPost = props.publishing?.posts.length === 0;

  return (
    <Fragment>
      <Header
        hasUpdate={props.hasUpdate}
        inEditing={props.inEditing}
        isError={isError}
        isLoading={isLoading}
        labelCancelButton={CONSTANTS.HEADER.BUTTON_CANCEL_ALL}
        labelSaveButton={CONSTANTS.HEADER.BUTTON_PUBLISHING}
        infoMessage={messageInfo}
        showInfo={showInfo}
        typeInfo="success"
        errorOnClickTryAgain={props.error ? props.loadCurrentPublishing : undefined}
        onClickCancelAll={props.onClickCancelAll}
        toggleEdit={props.toggleEdit}
        onClickPublish={props.onClickPublish}
      />
      {props.publishing && (
        <Fragment>
          <Divider style={{ margin: '2vh 0' }} />

          <Paper component="section" style={{ paddingBottom: '0%' }}>
            {TEMPORARY_DISABLE && (
              <DatePublishing disabled={!props.inEditing} date={props.publishing?.date} handleDateChange={props.handleDateChange} />
            )}

            <InfoCharactersLimits>
              <h3 id="Info-characters-Label">Characters do Feed da Alexa</h3>
              <CharactersLimit className="Characters-limit" charactersLenght={props.feedCharactersLength} />
            </InfoCharactersLimits>

            <UpdatesInfo
              hasUpdate={props.hasUpdate}
              added={props.publishing.addedPost.length}
              deleted={props.publishing.deletedPost.length}
              edited={props.publishing.editedPost.length}
            />
          </Paper>

          <Paper component="ul" style={{ paddingTop: '0%', paddingBottom: '20%' }}>
            {props.inEditing && <AddButton onClick={props.addNewPost} />}

            {!isEmptyPost && <ExpandAll />}

            {props.publishing.posts.map((p, i) => (
              <Post
                feedCharactersLength={props.feedCharactersLength}
                content={p.content}
                edited={p.edited}
                expand={props.expandPosts}
                id={p.id}
                index={i}
                inEditing={props.inEditing}
                isFirst={i === 0}
                isLast={i + 1 === props.publishing?.posts?.length}
                key={p.id}
                title={p.title}
                movePost={props.movePost}
                onClickDelete={props.deletePost}
                onClickResetEdit={props.resetEditPost}
                onClickSave={props.savePost}
              />
            ))}

            {isEmptyPost && <Alert type="warning" message={CONSTANTS.EMPTY_PUBLISHING} />}
          </Paper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PublishingsView;

const LabelUpdatesCount = styled.h6<{ hasUpdate: boolean }>`
  text-align: center;
  margin-top: 20px;
  padding: 1vh;
  border: solid 15px;
  border-color: ${({ theme, hasUpdate }) => (hasUpdate ? theme.colors.select : theme.colors.background.paper)};

  & > span {
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0 20px;
  }
`;

const InfoCharactersLimits = styled.section`
  display: contents;
  width: fit-content;
  text-align: center;
  padding: 0 2%;
  margin-left: 5%;

  #Info-characters-Label {
    text-transform: uppercase;
  }

  .Characters-limit {
    font-size: 1.5em;

    & > span {
      margin: 15px 0 5px 0;
    }
  }

  #Info-characters-Label {
    font-size: 25px;
  }

  #Info-characters-Label,
  h3 {
    color: rgb(0 0 0 / 50%);
  }

  h3 {
    font-size: 18px;
  }
`;
