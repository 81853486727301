import React from 'react';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  ExitToApp as ExitToAppIcon,
  Group as GroupIcon,
  PostAdd as PostAddIcon,
} from '@material-ui/icons';

import CONSTANTS from '../constants';
import { DRAWER_WIDTH } from '../styles';

type Props = {
  handleOptionMenu: (id: number) => void;
  handleMenu: () => void;
  isOpen: boolean;
  label: string;
};

const Menu: React.FC<Props> = (props: Props) => {
  const style = useStyles();
  const theme = useTheme();

  const MenuIcon = () => (theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />);

  return (
    <aside>
      <Drawer
        className={style.drawer}
        variant="persistent"
        anchor="left"
        open={props.isOpen}
        classes={{
          paper: style.drawerPaper,
        }}
      >
        <header className={style.drawerHeader}>
          <IconButton onClick={props.handleMenu}>
            <MenuIcon />
          </IconButton>
        </header>

        <Divider />

        <List>
          <ListItem button key={CONSTANTS.MENU.NEWS.id} onClick={() => props.handleOptionMenu(CONSTANTS.MENU.NEWS.id)}>
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary={CONSTANTS.MENU.NEWS.label} />
          </ListItem>

          <ListItem button key={CONSTANTS.MENU.USERS.id} onClick={() => props.handleOptionMenu(CONSTANTS.MENU.USERS.id)}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={CONSTANTS.MENU.USERS.label} />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem button key={CONSTANTS.MENU.EXIT.id} onClick={() => props.handleOptionMenu(CONSTANTS.MENU.EXIT.id)}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={CONSTANTS.MENU.EXIT.label} />
          </ListItem>
        </List>
      </Drawer>
    </aside>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export default Menu;
