import React from 'react';

import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import StringUtils from '../../../Utils/StringUtils';
import CONSTANTS from '../constants';

type Props = {
  sendLogin: (email: string, password: string) => void;
  disableInputs: boolean;
};

const FormLogin: React.FC<Props> = (props) => {
  const [state, setState] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const loginDataIsNotFilled = (): boolean => StringUtils.isNullOrEmpty(state.email) || StringUtils.isNullOrEmpty(state.password);

  const sendLodingData = () => props.sendLogin(state.email, state.password);

  const handleChange = (prop: 'password' | 'email') => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
  };

  const onKeyboardType = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && !loginDataIsNotFilled()) sendLodingData();
  };

  const ButtonLogin = () => (
    <Button
      className="Form-components Form-button"
      variant="contained"
      color="primary"
      disabled={loginDataIsNotFilled()}
      onClick={sendLodingData}
    >
      {CONSTANTS.BUTTONS.LOGIN.LABEL_ENTER}
    </Button>
  );

  return (
    <form onKeyPress={onKeyboardType}>
      <FormControl variant="outlined" className="Form-components">
        <TextField
          disabled={props.disableInputs}
          label={CONSTANTS.BUTTONS.LOGIN.LABEL_USER}
          variant="outlined"
          onChange={handleChange('email')}
        />
      </FormControl>

      <FormControl variant="outlined" disabled={props.disableInputs} className="Form-components">
        <InputLabel htmlFor="outlined-adornment-password">{CONSTANTS.BUTTONS.LOGIN.LABEL_PASSWORD}</InputLabel>
        <OutlinedInput
          type={state.showPassword ? 'text' : 'password'}
          value={state.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>

      <ButtonLogin />
    </form>
  );
};

export default FormLogin;
