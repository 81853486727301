import { createMuiTheme } from '@material-ui/core';
import { DefaultTheme } from 'styled-components';

const TEXT = '#0a0a0a';
const PAPER = '#fff';
const PRIMARY = {
  light: '#fffbe0',
  main: '#fdd92e',
  dark: '#dcb912',
};

const styled = (): DefaultTheme => ({
  colors: {
    primary: PRIMARY,
    background: {
      secunday: '#f3f3f3',
      primary: '#ffdc47',
      paper: PAPER,
    },
    select: '#e1ffe0',
    text: TEXT,
  },
});

const material = createMuiTheme({
  palette: {
    type: 'light',
    text: {
      disabled: 'rgb(0 0 0 / 58%)',
      primary: TEXT,
    },

    primary: { ...PRIMARY, contrastText: TEXT },

    background: {
      paper: PAPER,
    },
  },
});

export default {
  styled,
  material,
};
