import React from 'react';

import { ThemeProvider as ThemeProviderMaterial } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import { GlobalStyle } from './global';
import themes from './themes';

const ThemeGlobalProvider = ({ children }: any) => {
  return (
    <ThemeProvider theme={themes.styled}>
      <GlobalStyle />
      <ThemeProviderMaterial theme={themes.material}>{children}</ThemeProviderMaterial>
    </ThemeProvider>
  );
};

export default ThemeGlobalProvider;
