import React, { useState } from 'react';

import { User } from '../../../../../store/ducks/users/types';
import { debounce } from '../../../../../Utils';

import UserItemView from './UserItemView';

type Props = {
  className?: string;
  adminInputsDisabled: boolean;

  admin: boolean;
  id: string;
  email: string;
  name: string;
  index: number;
  inEditing: boolean;
  onChange: (user: User) => void;
  onClickDelete: (id: string) => void;
};

type State = {
  id: string;
  email: string;
  name: string;
  admin: boolean;
  password: string;
};

const UserItem: React.FC<Props> = (props: Props) => {
  const [user, setUser] = useState<State>(initialState(props));

  const onClickDelete = () => props.onClickDelete(user.id);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser((prev) => ({ ...prev, [name]: value, saved: false }));
    debounce(() => props.onChange({ ...user, [name]: value }), 500);
  };

  const onChangeAdmin = () => {
    setUser((prev) => ({ ...prev, admin: !prev.admin }));
    debounce(() => props.onChange({ ...user, admin: !user.admin }), 500);
  };

  return (
    <UserItemView
      adminInputsDisabled={props.adminInputsDisabled}
      admin={user.admin}
      className={props.className}
      email={user.email}
      name={user.name}
      password={user.password}
      index={props.index}
      inEditing={props.inEditing}
      onChange={onChange}
      onChangeAdmin={onChangeAdmin}
      onClickDelete={onClickDelete}
    />
  );
};

const initialState = (props: Props): State => ({
  email: props.email,
  id: props.id,
  admin: props.admin,
  name: props.name,
  password: '',
});

export default UserItem;
