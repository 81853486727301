import React from 'react';

import { Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { DeleteForeverOutlined as DeleteIcon } from '@material-ui/icons';

import InputText from '../../../../../components/InputText';

type Props = {
  className?: string;
  admin: boolean;
  email: string;
  name: string;
  password: string;
  index: number;
  inEditing: boolean;
  adminInputsDisabled: boolean;

  onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChangeAdmin: () => void;
  onClickDelete: () => void;
};

const UserItemView: React.FC<Props> = (props) => {
  return (
    <li className={props.className || ''}>
      {!props.adminInputsDisabled && (
        <IconButton disabled={!props.inEditing} aria-label="check" onClick={props.onChangeAdmin}>
          <Checkbox disabled={!props.inEditing} name="admin" checked={props.admin} />
        </IconButton>
      )}
      
      <InputText disabled={!props.inEditing} onChange={props.onChange} text={props.name} label="name" maxLength={70} />
      <InputText className='E-mail' disabled={!props.inEditing} onChange={props.onChange} text={props.email} label="email" maxLength={70} />
      <InputText disabled={!props.inEditing} onChange={props.onChange} text={props.password} label="password" maxLength={70} />

      {!props.adminInputsDisabled && (
        <IconButton disabled={!props.inEditing} edge="end" aria-label="delete" onClick={props.onClickDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </li>
  );
};

export default UserItemView;
