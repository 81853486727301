import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  body {
    background: ${({ theme }) => theme.colors.background.secunday};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    & {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
    }

    color: ${({ theme }) => theme.colors.text};
    font-size: 21px;
    line-height: 32px;
    
    .Mui-focused,
    .MuiTypography-colorPrimary {
      color: ${({ theme }) => theme.colors.primary.dark} !important;
    }


    .Dialog-show .MuiTypography-root,
    .MuiButton-label {
      font-weight: bold;
    }

    .Buttons {
      .Mui-disabled {
        color: rgb(0 0 0 / 30%);
      }
    }

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 29px;
    }
  }
`;
