import React from 'react';

import { Button, DialogContentText, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import styled from 'styled-components';

import { Loading } from '../../../../components';
import Alert from '../../../../components/Alert';
import Dialog from '../../../../components/Dialog';
import CONSTANTS from '../../constants/';

type Props = {
  disabledButton: boolean;
  email: string;
  error: boolean;
  handleChange: (email: string) => void;
  handleDialog: () => void;
  loading: boolean;
  onClickSendEmail: () => void;
  open: boolean;
  success: boolean;
};

const ChangePasswordView: React.FC<Props> = (props) => {
  return (
    <Container>
      <Link component="button" variant="body2" onClick={props.handleDialog}>
        <b>{ CONSTANTS.MESSAGES.RESET_PASSWORD.LABEL_REQUEST_PASSWORD}</b>
      </Link>

      <Dialog open={props.open} onClickClose={props.handleDialog} title={ CONSTANTS.MESSAGES.RESET_PASSWORD.LABEL_REQUEST_PASSWORD}>
        <DialogContent>
          <DialogContentText>{ CONSTANTS.MESSAGES.RESET_PASSWORD.TEXT_INTRO}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            onChange={(e) => props.handleChange(e.target.value)}
          />

          <Button id="Button-send" variant="contained" color="primary" disabled={props.disabledButton} onClick={props.onClickSendEmail}>
            { CONSTANTS.BUTTONS.RESET_PASSWORD.LABEL_BUTTON}
          </Button>

          <div id="Info">
            {props.loading && <Loading />}
            {props.error && <Alert message={ CONSTANTS.MESSAGES.RESET_PASSWORD.ERROR_REQUEST_API} type="error" />}
            {props.success && <Alert message={ CONSTANTS.MESSAGES.RESET_PASSWORD.SUCCESS} type="success" />}
          </div>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

const Container = styled.section`
  margin-bottom: 5vh;
`;

const DialogContent = styled.article`
  display: flex;
  flex-direction: column;

  #Button-send {
    width: fit-content;
    margin: 5%;
    align-self: center;
  }

  #Info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default ChangePasswordView;
