import { all, takeLatest } from 'redux-saga/effects';

import { loadAuth } from './login/sagas';
import { Types as LoginTypes } from './login/types';
import { loadPublishing } from './publishing/sagas';
import { Types as PublishingTypes } from './publishing/types';
import { loadUsers } from './users/sagas';
import { Types as UsersTypes } from './users/types';

export default function* rootSaga() {
  return yield all([
    takeLatest(LoginTypes.LOAD_REQUEST, loadAuth),
    takeLatest(PublishingTypes.LOAD_REQUEST, loadPublishing),
    takeLatest(UsersTypes.LOAD_REQUEST, loadUsers),
  ]);
}
