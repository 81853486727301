import React, { useState } from 'react';

import { ApiService } from '../../../../services';
import StringUtils from '../../../../Utils/StringUtils';

import ChangePasswordView from './ChangePasswordView';

const ChangePassword: React.FC = (props) => {
  const [state, setState] = useState({
    email: '',
    error: false,
    loading: false,
    open: false,
    success: false,
    disabledButton: true,
  });

  const handleDialog = () => setState((prev) => ({ ...state, open: !prev.open }));

  const handleChange = (email: string) => {
    setState({ ...state, email, disabledButton: false });
  };

  const sendEmail = (email: string): void => {
    setState((prev) => ({ ...prev, loading: true, error: false, success: false, disabledButton: true }));

    ApiService.resetPassword(email)
      .then(() => setState((prev) => ({ ...prev, success: true, disabledButton: true, loading: false })))
      .catch(() => setState((prev) => ({ ...prev, loading: false, error: true, disabledButton: false })));
  };

  return (
    <ChangePasswordView
      disabledButton={state.disabledButton || StringUtils.isNullOrEmpty(state.email)}
      email={state.email}
      error={state.error}
      handleChange={handleChange}
      handleDialog={handleDialog}
      loading={state.loading}
      onClickSendEmail={() => sendEmail(state.email)}
      open={state.open}
      success={state.success}
    ></ChangePasswordView>
  );
};

export default ChangePassword;
