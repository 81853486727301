import React, { useEffect, useState } from 'react';

import { Post as PostType } from '../../../../../../store/ducks/publishing/types';

import PostView from './PostView';

type Props = {
  feedCharactersLength: number;
  content: string;
  edited?: boolean;
  expand: boolean;
  id: string;
  index: number;
  inEditing: boolean;
  isFirst: boolean;
  isLast: boolean;
  title: string;

  onClickSave: (post: PostType) => void;
  onClickDelete: (id: string) => void;
  onClickResetEdit: (post: PostType) => void;
  movePost: (action: 'up' | 'down', id: string) => void;
};

type State = {
  content: string;
  id: string;
  edited?: boolean;
  title: string;
  saved: boolean;
  expand: boolean;
};

const Post: React.FC<Props> = (props: Props) => {
  const [state, setState] = useState<State>(initialState(props));

  /**
   *  @var charactersLenght: Determina o total de character de todo o feed + total character do post em edição
   *
   *  @var props.feedCharactersLength: é o total de character de todo o feed
   *  @var props.content.length: total de character do post atual na loja da alexa
   *  @var state.content.length: total de character do post em edição
   */
  const charactersLenght = props.feedCharactersLength - props.content.length + state.content.length;

  const onClickCancel = () => setState({ ...props, saved: true });
  const onClickDelete = () => props.onClickDelete(state.id);
  const onClickResetEdit = () => props.onClickResetEdit({ ...state });
  const onClickMove = (action: 'up' | 'down') => props.movePost(action, state.id);
  const handleExpand = () => setState((prev) => ({ ...prev, expand: !prev.expand }));

  const onClickSave = () => {
    setState((prev) => ({ ...prev, edited: true, saved: true }));
    props.onClickSave({ ...state, edited: true });
  };

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value?.replace('\n/g', ''), saved: false }));
  };

  useEffect(() => {
    if (props.inEditing === false || props.edited !== state.edited || props.expand !== state.expand) {
      setState(initialState(props));
    }
  }, [props.inEditing, props.edited, props.expand]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PostView
      charactersLenght={charactersLenght}
      charactersPostLenght={state.content.length}
      content={state.content}
      edited={state.edited}
      expand={state.expand}
      index={props.index}
      inEditing={props.inEditing}
      isFirst={props.isFirst}
      isLast={props.isLast}
      salved={state.saved}
      title={state.title}
      handleExpand={handleExpand}
      onChange={onChange}
      onClickCancel={onClickCancel}
      onClickDelete={onClickDelete}
      onClickSave={onClickSave}
      onClickResetEdit={onClickResetEdit}
      onClickMove={onClickMove}
    />
  );
};

const initialState = (props: Props): State => ({
  id: props.id,
  title: props.title,
  content: props.content,
  edited: props.edited,
  expand: props.expand,
  saved: true,
});

export default Post;
