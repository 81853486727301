import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import { ApiService } from '../../../../services';
import { ApplicationState } from '../../../../store';
import { updateUserLogged } from '../../../../store/ducks/login/actions';
import { initialUser, isNotUpdatedUser } from '../../../../store/ducks/login/authReducer';
import { loadRequest, updateRequest } from '../../../../store/ducks/users/actions';
import { User } from '../../../../store/ducks/users/types';
import { genereteId } from '../../../../Utils';
import ListUtils from '../../../../Utils/ListUtils';
import StringUtils from '../../../../Utils/StringUtils';

import UsersView from './UsersView';

type StateProps = {
  users: Array<User>;
  loadingUsers: boolean;
  errorLoadingUsers: boolean;
  userLogged: User;
  isAdmin: boolean;
};

type DispatchProps = {
  loadCurrentUsers: () => void;
  updateCurrentUsers: (users: Array<User>) => void;
  updateUserLogged: (user: User) => void;
};

type State = {
  users: Array<User>;
  hasUpdate: boolean;
  inEditing: boolean;
  savingUsers: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
};

const Publishing: React.FC<StateProps & DispatchProps> = (props) => {
  const [state, setState] = React.useState<State>(initialState());

  const users = ListUtils.isNullOrEmpty(state.users) ? props.users : state.users;

  if (ListUtils.isNullOrEmpty(props.users)) {
    props.loadCurrentUsers();
  }

  if (isNotUpdatedUser(props.userLogged)) {
    const userUpdated = props.users.find((u) => u.email === props.userLogged.email);
    !!userUpdated && props.updateUserLogged(userUpdated);
  }

  const cancellAllUptdates = () => setState(initialState());
  const deleteUser = (id: string) => setState((prev) => ({ ...prev, hasUpdate: true, users: state.users.filter((u) => u.id !== id) }));
  const addNewUser = () => {
    const newUser = initialUser();
    newUser.id = genereteId('');
    setState((prev) => ({ ...prev, hasUpdate: true, users: [newUser].concat(state.users) }));
  };

  const toggleEdit = () =>
    setState((prev) => ({
      ...prev,
      inEditing: !prev.inEditing,
      users: prev.users.length === 0 ? props.users.map((u) => ({ ...u, id: u.id + '-edit' })) : prev.users,
      savingUsers: newStateSavingUsers(false, false, false),
    }));

  const saveUser = (user: User) => {
    let index;
    state.users.find((u, i) => (u.id === user.id ? (index = i) : false));

    if (index === undefined) return;

    const newUsers = [...state.users];
    newUsers[index] = user;

    setState((prev) => ({
      ...prev,
      hasUpdate: true,
      users: newUsers,
    }));
  };

  const publishChanges = () => {
    setState((prev) => ({ ...prev, savingUsers: newStateSavingUsers(false, false, true) }));

    const success = () => {
      props.updateCurrentUsers(state.users);
      setState((prev) => ({
        ...prev,
        users: [],
        savingUsers: newStateSavingUsers(true, false, false),
        inEditing: false,
        hasUpdate: false,
      }));
    };

    const formatUsers = (u: User) => ({
      name: u.name,
      email: u.email,
      admin: u.admin,
      password: StringUtils.isNullOrEmpty(u.password) ? undefined : u.password,
    });

    ApiService.saveUsers(state.users.map(formatUsers))
      .then(success)
      .catch(() => setState((prev) => ({ ...prev, savingUsers: newStateSavingUsers(false, true, false) })));
  };

  return (
    <UsersView
      error={props.errorLoadingUsers}
      hasUpdate={state.hasUpdate}
      inEditing={state.inEditing}
      loading={props.loadingUsers}
      savingUsers={state.savingUsers}
      userLoggedIsAdmin={props.isAdmin}
      users={users}
      addNewUser={addNewUser}
      deleteUser={deleteUser}
      loadCurrentUsers={props.loadCurrentUsers}
      onClickCancelAll={cancellAllUptdates}
      toggleEdit={toggleEdit}
      onClickPublish={publishChanges}
      saveUser={saveUser}
    />
  );
};

const initialState = (): State => ({
  users: [],
  hasUpdate: false,
  inEditing: false,
  savingUsers: {
    loading: false,
    error: false,
    success: false,
  },
});

const newStateSavingUsers = (success: boolean, error: boolean, loading: boolean) => ({ success, error, loading });

const mapStateToProps = (state: ApplicationState) => ({
  userLogged: state.auth.user,
  isAdmin: state.auth.user.admin,
  users: state.users.data,
  loadingUsers: state.users.loading,
  errorLoadingUsers: state.users.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ loadCurrentUsers: loadRequest, updateCurrentUsers: updateRequest, updateUserLogged: updateUserLogged }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Publishing);
