const CONSTANTS = {
  BUTTONS_LABEL: {
    DELETE: 'Deletar Post',
    DISCART: 'Descartar Alterações',
    CANCEL: 'Cancelar',
    SAVE: 'Salvar',
  },

  TITLE_LIMIT: 500,
};

export default CONSTANTS;
