import React from 'react';

import AlertView from '@material-ui/lab/Alert';

type Props = {
  message: string;
  type: 'error' | 'warning' | 'success';
  className?: string;
};

const Alert: React.FC<Props> = (props: Props) => {
  const messages = props.message?.split('\n');
  return (
    <AlertView className={props.className} component="p" variant="outlined" severity={props.type} style={{ justifyContent: 'center' }}>
      {messages.map((message, i) => (
        <React.Fragment key={i}>
          <b>{message}</b> <br />
        </React.Fragment>
      ))}
    </AlertView>
  );
};

export default Alert;
