import { put } from 'redux-saga/effects';

import { ApiService } from '../../../services';
import { PublishingDTO } from '../../../services/api';
import { genereteId } from '../../../Utils';
import ErrorUtils from '../../../Utils/ErrorUtils';

import { loadSuccess, loadFailure } from './actions';
import { Publishing } from './types';

export function* loadPublishing() {
  try {
    const response = yield ApiService.getCurrentPublishing();
    const data: PublishingDTO = response?.data;

    if (!response || response.status !== 200) {
      throw ErrorUtils.CustomException(`Error get publiation API response - ${JSON.stringify(response)}`, response?.status?.toString());
    }

    if (!data) {
      throw new Error('Invalid response from authentication API');
    }

    const publishing: Publishing = {
      ...data,
      date: new Date(data.date),
      posts: data.posts.map((p, i) => ({ ...p, id: genereteId(i.toString()) })),
      deletedPost: [],
      editedPost: [],
      addedPost: [],
    };

    yield put(loadSuccess(publishing));
  } catch (err) {
    yield put(loadFailure());
    console.error(err);
  }
}
