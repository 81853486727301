import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators, Dispatch } from 'redux';

import { ApiService } from '../../../../services';
import { PublishingDTO } from '../../../../services/api';
import { ApplicationState } from '../../../../store';
import { loadRequest, updateRequest } from '../../../../store/ducks/publishing/actions';
import { Post, Publishing as PublishingType } from '../../../../store/ducks/publishing/types';
import { genereteId } from '../../../../Utils';

import {
  addDateInPublishing,
  addNewPostInPublishing,
  deletePostInPublishing,
  movePostInPublishing,
  resetEditPostInPublishing,
} from './helpers';
import PublishingView from './PublishingView';

type StateProps = {
  publishing: PublishingType;
  loadingPublishing: boolean;
  errorLoadingPublishing: boolean;
};

type DispatchProps = {
  loadCurrentPublishing: () => void;
  updateCurrentPublishing: (publishing: PublishingType) => void;
};

type State = {
  newPublishing: PublishingType;
  hasUpdate: boolean;
  inEditing: boolean;
  expandPosts: boolean;
  publishingToStore: {
    loading: boolean;
    error: boolean;
    success: boolean;
  };
};

const Publishing: React.FC<StateProps & DispatchProps> = (props) => {
  const [state, setState] = React.useState<State>(initialState());

  const publishing = state.newPublishing === null ? props.publishing : state.newPublishing;

  if (props.publishing === null && !props.loadingPublishing && !props.errorLoadingPublishing) {
    props.loadCurrentPublishing();
  }

  const cancellAllUptdates = () => setState(initialState());
  const hasUpdate = (p: PublishingType) => !!p?.addedPost.length || !!p?.editedPost.length || !!p?.deletedPost.length;
  const handleExpandPosts = () => setState((prev) => ({ ...prev, expandPosts: !prev.expandPosts }));
  const handleDateChange = (date: Date | null) => {
    setState((prev) => ({ ...prev, newPublishing: addDateInPublishing(date, prev.newPublishing), hasUpdate: true }));
  };

  const toggleEdit = () =>
    setState((prev) => ({
      ...prev,
      inEditing: !prev.inEditing,
      newPublishing: prev.newPublishing || props.publishing,
      publishingToStore: newStatePublishingToStore(false, false, false),
    }));

  const addNewPost = (newPost: Post) =>
    setState((prev) => ({
      ...prev,
      hasUpdate: true,
      newPublishing: addNewPostInPublishing(newPost, prev.newPublishing, props.publishing),
    }));

  const deletePost = (id: string) =>
    setState((prev) => ({
      ...prev,
      hasUpdate: true,
      newPublishing: deletePostInPublishing(id, prev.newPublishing, props.publishing),
    }));

  const resetEditPost = (post: Post) =>
    setState((prev) => ({
      ...prev,
      hasUpdate: hasUpdate(prev.newPublishing),
      newPublishing: resetEditPostInPublishing(post, prev.newPublishing, props.publishing),
    }));

  const movePost = (action: 'up' | 'down', id: string) =>
    setState((prev) => ({
      ...prev,
      hasUpdate: true,
      newPublishing: movePostInPublishing(action, id, prev.newPublishing),
    }));

  const publishIntoStore = () => {
    setState((prev) => ({ ...prev, publishingToStore: newStatePublishingToStore(false, false, true) }));

    const success = () => {
      props.updateCurrentPublishing(state.newPublishing);
      setState((prev) => ({
        ...prev,
        newPublishing: null,
        publishingToStore: newStatePublishingToStore(true, false, false),
        inEditing: false,
        hasUpdate: false,
      }));
    };

    ApiService.sendPublishing(buildPublishingDTO(state.newPublishing))
      .then(success)
      .catch(() => setState((prev) => ({ ...prev, publishingToStore: newStatePublishingToStore(false, true, false) })));
  };

  const getFeedCharactersLength = (): number => {
    const publishing = state.newPublishing || props.publishing;
    if (publishing === null) return 0;
    else {
      const postsLenght = publishing.posts.map((p) => p.content.length);
      return postsLenght.length === 0 ? postsLenght.length : postsLenght.reduce((a, b) => a + b);
    }
  };

  const feedCharactersLength = getFeedCharactersLength();

  return (
    <PublishingView
      error={props.errorLoadingPublishing}
      expandPosts={state.expandPosts}
      hasUpdate={state.hasUpdate}
      inEditing={state.inEditing}
      loading={props.loadingPublishing}
      publishing={publishing}
      publishingToStore={state.publishingToStore}
      feedCharactersLength={feedCharactersLength}
      addNewPost={() => addNewPost(buildDefaultNewPost())}
      deletePost={deletePost}
      handleExpandPosts={handleExpandPosts}
      handleDateChange={handleDateChange}
      movePost={movePost}
      onClickCancelAll={cancellAllUptdates}
      toggleEdit={toggleEdit}
      onClickPublish={publishIntoStore}
      loadCurrentPublishing={props.loadCurrentPublishing}
      resetEditPost={resetEditPost}
      savePost={addNewPost}
    />
  );
};

const buildDefaultNewPost = () => ({
  id: genereteId(Date.now().toString()),
  content: '',
  title: '',
  edited: true,
});

const initialState = () => ({
  newPublishing: null,
  hasUpdate: false,
  inEditing: false,
  expandPosts: false,
  updatesCount: 0,
  successOnPublishing: false,
  publishingToStore: {
    loading: false,
    error: false,
    success: false,
  },
});

const newStatePublishingToStore = (success: boolean, error: boolean, loading: boolean) => ({ success, error, loading });

const buildPublishingDTO = (publishing: PublishingType): PublishingDTO => {
  if (publishing === null) return null;
  return {
    date: publishing.date === null ? '' : publishing.date.toJSON(),
    posts: publishing.posts.map((p) => ({ title: p.title, content: p.content })),
    redirectionUrl: publishing.redirectionUrl,
  };
};

const mapStateToProps = (state: ApplicationState) => ({
  publishing: state.publishing.data,
  loadingPublishing: state.publishing.loading,
  errorLoadingPublishing: state.publishing.error,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ loadCurrentPublishing: loadRequest, updateCurrentPublishing: updateRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Publishing);
