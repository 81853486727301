import { AnyAction } from 'redux';

import { User } from '../users/types';

export enum Types {
  LOGOUT = '@auth/LOGOUT',
  UPDATE_USER_LOGGED = '@auth/UPDATE_USER_LOGGED',
  LOAD_REQUEST = '@auth/LOAD_RESQUEST',
  LOAD_SUCCESS = '@auth/LOAD_SUCCESS',
  LOAD_FAILURE = '@auth/LOAD_FAILURE',
  CLEAR_STATE = '@auth/CLEAR_STATE',
}

export type State = {
  readonly authenticated: boolean;
  readonly user: User;
  readonly wrongPassword: boolean;
  readonly loading: boolean;
  readonly error: boolean;
};

export type Payload = {
  authenticated: boolean;
  wrongPassword: boolean;
  user: User;
};

export interface PayloadLoadRequest extends AnyAction {
  payload: {
    user: string;
    password: string;
  };
}

export interface Action extends AnyAction {
  payload: Payload;
}
