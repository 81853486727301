import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { bindActionCreators, Dispatch } from 'redux';

import CONSTANTS from '../../constants';
import { AuthService } from '../../services';
import { logout } from '../../store/ducks/login/actions';

type OwnProps = {
  logout: () => void;
};

type Props = OwnProps;

const Logout: React.FC<Props> = (props: Props) => {
  AuthService.logout();
  props.logout();

  return <Redirect to={{ pathname: CONSTANTS.ROUTES.LOGIN }} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ logout }, dispatch);

export default connect(null, mapDispatchToProps)(Logout);
