import React from 'react';

import styled from 'styled-components';

const Container = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${({ theme }) => theme.colors.primary.dark};

  @media (max-width: 500px) {
    font-size: 0.8rem;
    height: 130px;
    align-items: start;
    padding-top: 25px;
  }
`;

const Footer: React.FC = () => {
  return <Container>© Copyright 2019-{`${new Date().getFullYear()}`} Matheus Ícaro</Container>;
};

export default Footer;
