import StringUtils from './StringUtils';

const CustomException = (message: string, code: string) => {
  const error = new Error(message);
  error.name = code;
  return error;
};

const getMetadataRequestError = (error: Error) => {
  let code = error?.name ? StringUtils.getNumbers(error.name) : null;
  code = code || StringUtils.getNumbers(error?.message);
  return {
    code: code,
    message: error?.message,
  };
};

const ErrorUtils = {
  CustomException,
  getMetadataRequestError,
};

export default ErrorUtils;
